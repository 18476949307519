import React, { useContext } from "react";
import "./research.scss";
import ResearchCard from "../../components/research/research";
import { researchSection } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function Research() {
    const { isDark } = useContext(StyleContext);

    if (!researchSection.display) {
        return null;
    }

    return (
        <Fade bottom duration={1000} distance="20px">
            <div className="main" id="research">
                <div className="research-main-div">
                    <div className="research-header">
                        <h1
                            className={
                                isDark
                                    ? "dark-mode heading research-heading"
                                    : "heading research-heading"
                            }
                        >
                            {researchSection.title}
                        </h1>
                        <p
                            className={
                                isDark
                                    ? "dark-mode subTitle research-subtitle"
                                    : "subTitle research-subtitle"
                            }
                        >
                            {researchSection.intro}
                        </p>
                    </div>
                    <div className="research-cards-div">
                        {researchSection.papers.map((paper, i) => {
                            return (
                                <ResearchCard
                                    key={i}
                                    isDark={isDark}
                                    paper={{
                                        title: paper.title,
                                        advisors: paper.advisors,
                                        description: paper.description,
                                        link: paper.link,
                                        status: paper.status,
                                        buttonText: paper.buttonText,
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </Fade>
    );
}
