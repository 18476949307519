import React, { useContext } from "react";
import "./research.scss";
import StyleContext from "../../contexts/StyleContext";

export default function Research({ paper, isDark }) {
    const { setShowIframe, setIframeUrl } = useContext(StyleContext);

    return (
        <div className={isDark ? "dark-mode research-card" : "research-card"}>
            <div className="research-detail-div">
                <h5 className={isDark ? "dark-mode research-title" : "research-title"}>
                    {paper.title}
                </h5>
                <p className={isDark ? "dark-mode research-advisors" : "research-advisors"}>
                    <strong>Advisors:</strong> {paper.advisors.join(", ")}
                </p>
                <p
                    className={
                        isDark ? "dark-mode research-description" : "research-description"
                    }
                >
                    {paper.description}
                </p>
                <p
                    className={isDark ? "dark-mode research-status" : "research-status"}
                >
                    Status: {paper.status}
                </p>
                <div className="research-card-footer">
                    <span
                        className={isDark ? "dark-mode research-link" : "research-link"}
                        onClick={() => {
                            setShowIframe(true);
                            setIframeUrl(paper.link);
                        }}
                    >
                        {paper.buttonText}
                    </span>
                </div>
            </div>
        </div>
    );
}
