import React, { useState } from "react";
import "./SoftwareSkill.scss";
import { skillsSection } from "../../portfolio";
import ocamlLogo from "../../assets/images/ocaml.png"
import ocamlHoverLogo from "../../assets/images/ocaml-hover.png"

export default function SoftwareSkill() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsSection.softwareSkills.map((skills, i) => {
            return (
              <li
                key={i}
                className="software-skill-inline"
                name={skills.skillName}
                onMouseEnter={() => setHoveredIndex(i)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {skills.skillName === "oCaml" ? (
                  <img
                    src={hoveredIndex === i ? ocamlHoverLogo : ocamlLogo}
                    alt="OCaml Logo"
                    className="custom-skill-icon"
                  />
                ) : (
                  <i className={skills.fontAwesomeClassname}></i>
                )}
                <p>{skills.skillName}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
